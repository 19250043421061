import React, { useCallback, useEffect } from 'react'
import useFetch from 'use-http'
import _memoize from 'lodash/memoize'
import moment from 'moment'
import { Link, Route, Switch, useRouteMatch } from "react-router-dom"
import { Button } from '@kyper/button'
import { Text } from '@kyper/text'
import { MessageBox } from '@kyper/messagebox'

import { Table } from '../shared/Table'


import { RequestCard } from '../shared/RequestCard'

import { ASK_TIME_FRAME, ASK_TIME_MESSAGE, checkAskDates } from '../../utils/asks'

const _checkAskDates = _memoize(checkAskDates)

const Landing = () => {
  const { loading, error, data = [] } = useFetch('/api/v1/requests', {}, [])
  // const { delete: deleteAsk, response } = useFetch()
  const { path, url } = useRouteMatch();
  const eligibilityStatus = _checkAskDates(data)
  // const eligibilityStatus = ASK_TIME_FRAME.VALID
  const pending = data.filter(ask => ask.status === 'pending')
  const sorted = data.sort((a, b) => b.id - a.id)

  // const handleDeletePending = useCallback(async () => {
  //   await deleteAsk(`/api/v1/requests/${pending[0].id}`)

  //   if (response.ok) {
  //     window.location.reload()
  //   }
  // }, [deleteAsk, response, pending])

  if (loading) {
    return (
      <React.Fragment>
        Loading
      </React.Fragment>
    )
  }

  if (error) {
    return (
      <React.Fragment>
        Oops, something went wrong.
      </React.Fragment>
    )
  }

  return (
    <div>
      {eligibilityStatus !== ASK_TIME_FRAME.VALID ? (
        <MessageBox title="Error" variant="error" style={{ marginBottom: 20 }}>
          {ASK_TIME_MESSAGE[eligibilityStatus]}
        </MessageBox>
      ) : (
        <Link to={`${url}/new`} style={{ textDecoration: 'none' }}>
          <Button variant="primary" style={{ marginBottom: 20 }}>
            Create New Ask
          </Button>
        </Link>
      )}

      <Table>
        <thead>
          <tr>
            <th>Created Date</th>
            <th>Days Since</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {sorted.map(row => {
            const created = moment(row.created_at)

            return (
              <tr key={row.id}>
                <td>{created.format('MM/DD/YYYY')}</td>
                <td>{moment().startOf('day').diff(created.startOf('day'), 'days')}</td>
                <td>{row.status}</td>
                <td>
                  {row.status === 'pending' || row.status === 'denied' ? (
                    <Link to={`${url}/edit/${row.id}`} style={{ textDecoration: 'none' }}>
                      <Button variant="primary" style={{ marginBottom: 20 }}>
                        Edit WTF
                      </Button>
                    </Link>
                  ) : null}
                </td>
              </tr>
            )
          })}
          <tr>
          </tr>
        </tbody>
        {}
      </Table>
    </div>

  )
}

export default Landing